/**
 * Clients
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        clientList: {},
        clientLoading: false,
        clientPagination: 1,
        clientPageSize: 20,
        clientFilters: {
            accountName: undefined,
        },
        clientDetail: {},
        ACTIVE_STATUS_MAP: {
            0: 'debugging',
            1: 'unactivated',
            2: 'activated',
        },
        // clientModel
        clientModelList: {},
        basicPriceList: [],
        basicPriceLoading: false,
        currencyTypeList: [],
        currencyTypeLoading: false,
        // verifiedClient
        verifiedClientDetail: [],
        verifiedClientLoading: false,
    },

    getters: {
        stat(state) {
            const { stat: { total = 0, workArea = 0, zzNum = 0, sortieCount = 0, modelNum = 0 } = {} } = state.clientList;
            return { total, workArea, zzNum, sortieCount, modelNum };
        },

        activeStatusLabel: state => (status) => {
            const { [status]: label } = state.ACTIVE_STATUS_MAP;
            return label || '-';
        },

        currencyTypeOptions(state) {
            const temp = {};
            state.currencyTypeList.forEach((item) => {
                temp[item.code] = item.DictValue;
            });
            return temp;
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        requestRentalSmsVerifyCode(store, { phoneNum }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_RENTAL_VERIFY_CODE, {
                    params: { phoneNum },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 获取基础价格
        getBasicPriceList({ commit }) {
            const type = 'basicPrice';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_BASIC_PRICE_LIST_BY_ACCOUNT).then(({ data = [] } = {}) => {
                    // console.log('records', data);
                    commit(types.UPDATE_LIST, { type, list: data });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 获取基础价格
        getCurrencyType({ commit }) {
            const type = 'currencyType';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_CURRENCY_TYPE_BY_ACCOUNT).then(({ data = [] } = {}) => {
                    console.log('records', data);
                    commit(types.UPDATE_LIST, { type, list: data });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    // resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 获取客户列表
        getClientList({ commit, state }, pagination = 1) {
            const type = 'client';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_CLIENT_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then(({ data = [] } = {}) => {
                    commit(types.UPDATE_LIST, { type, list: data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        getPureClientList() {
            // const type = 'client';

            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_CLIENT_LIST, {
                    params: {
                        pageIndex: 1,
                        size: 999,
                        // ...state[`${type}Filters`],
                    },
                }).then(({ data }) => {
                    // console.log('records', data);
                    // commit(types.UPDATE_LIST, { type, list: data });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },


        setBasicPrice({ dispatch }, formData) {
            const type = 'basicPrice';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.SET_BASIC_PRICE_BY_ACCOUNT, formData).then((response) => {
                    dispatch(`get${Type}List`);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        createClient({ dispatch, state }, formData) {
            const type = 'client';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_CLIENT, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        //
        // // 更变分页Size
        changeClientListPageSize({ commit, dispatch, state }, size) {
            const type = 'client';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 更新过滤条件
        changeClientListFilters({ commit, dispatch, state }, filters) {
            const type = 'client';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 获取详情
        getClientDetail({ commit }, phone) {
            const type = 'client';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
                    params: {
                        phone,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data || {} });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // getClientDetail({ commit }, clientId) {
        //     const type = 'client';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
        //             params: {
        //                 clientId,
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        //
        // // 清空农机详情数据
        // clearClientDetail({ commit }) {
        //     const type = 'client';
        //
        //     commit(types.UPDATE_DETAIL, { type, detail: {} });
        // },
        //
        // // 更新农机数据
        updateClient({ dispatch, commit, state }, { id, muUnitPrice, durationPrice }) {
            const type = 'client';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.post(urls.UPLOAD_CLIENT, { id, muUnitPrice, durationPrice }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },
        //
        // // 编辑农机制造商标识
        // updateManufacturerSign({ dispatch, commit, state }, { clientId, zzclientNum }) {
        //     const type = 'client';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_MANUFACTURER_SIGN, clientId);
        //         Vue.http.put(url, { zzclientNum }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机锁定状态
        // updateClientLock({ dispatch, commit, state }, { clientId, opr, password }) {
        //     const type = 'client';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.put(urls.UPDATE_client_LOCK, { clientId, opr, password }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机销售状态
        // updateClientSaleStatus({ dispatch, commit, state }, { clientId, saleType }) {
        //     const type = 'client';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_client_SALE_STATUS, clientId);
        //         Vue.http.put(url, { saleType }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 获取农机型号下拉列表
        // getClientModelList({ commit }) {
        //     const type = 'clientModel';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_OWN_client_MODEL_SELECT_LIST).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机类型
        // updateDeviceType({ dispatch, state }, { clientId, deviceType, sprayWidth }) {
        //     const type = 'client';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.UPDATE_DEVICE_TYPE, clientId);
        //         Vue.http.put(url, { deviceType, sprayWidth }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // 获取已验证的客户信息
        getVerifiedClient({ commit }) {
            const type = 'verifiedClient';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_VERIFIED_CLIENT).then(({ data }) => {
                    commit(types.UPDATE_DETAIL, { type, detail: data || [] });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 获取制造商客户信息
        getManufacturerClient(_, phone) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_MANUFACTURER_CLIENT, {
                    params: {
                        phone,
                    },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 激活客户账户
        activeClient({ dispatch }, { yyAccountId, code }) {
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.ACTIVE_CLIENT, { yyAccountId, code }).then((response) => {
                    dispatch('getVerifiedClient');
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // end
    },
};
