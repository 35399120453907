/**
 * DroneHistory
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

const UPDATE_CURRENT_DRONE_ID = 'UPDATE_CURRENT_DRONE_ID';

export default {
    namespaced: true,

    state: {
        // droneHistory
        droneId: 0,
        droneHistoryList: {},
        droneHistoryLoading: false,
        droneHistoryPagination: 1,
        droneHistoryPageSize: 20,
        droneHistoryFilters: {
            order: '1',
            startTime: '',
            endTime: '',
        },
        droneHistoryDetail: {},
        // droneHistoryStat
        droneHistoryStatDetail: {},
        // batchDroneHistoryDetails
        batchDroneHistoryDetails: {},
        // deviceWorkingPicture
        deviceWorkingPictureList: [],
    },

    getters: {
        // 已排序的轨迹点
        orderedOrbitPoints(state) {
            if (!Object.keys(state.droneHistoryDetail).length) {
                return [];
            }
            const { orbit: { droneInfos = [] } = {} } = state.droneHistoryDetail;
            // 排序→去污→去重
            const result = [];
            droneInfos.sort((a, b) => a.recordTime - b.recordTime).forEach((item) => {
                const { lng, lat } = item;
                if (lng < -180 || lng > 180 || lat < -90 || lat > 90) {
                    return;
                }
                if (!result.length) {
                    result.push(item);
                    return;
                }
                const lastPoint = result[result.length - 1];
                if (item.recordTime !== lastPoint.recordTime) {
                    result.push(item);
                }
            });
            return result;
        },
        // 计算某个架次作业的平均高度
        avgHeight(state, getters) {
            let sum = 0;
            const count = getters.orderedOrbitPoints.length;
            if (!count) {
                return 0;
            }
            getters.orderedOrbitPoints.forEach((item) => {
                sum += item.height;
            });
            return (sum / count).toFixed(2);
        },
        // 计算某个架次作业的平均速度
        avgSpeed(state, getters) {
            const count = getters.orderedOrbitPoints.length;
            if (!count) return 0;
            const accSpeed = getters.orderedOrbitPoints.reduce((acc, cur) => acc + cur.xspeed, 0);
            return (accSpeed / count).toFixed(2);
        },
    },

    mutations: {
        [UPDATE_CURRENT_DRONE_ID](state, droneId) {
            state.droneId = droneId;
        },
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_GROUP](state, { group, key, value }) {
            if (!key) {
                state[group] = {};
                return;
            }
            state[group] = {
                ...state[group],
                [key]: value,
            };
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // 清空历史列表
        clearDroneHistoryList({ commit }) {
            const type = 'droneHistory';

            commit(types.UPDATE_LIST, { type, list: [] });
            commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination: 1 });
        },

        // 更改当前农机ID
        changeCurrentDroneId({ commit }, droneId) {
            commit(UPDATE_CURRENT_DRONE_ID, droneId);
        },

        // droneHistory列表
        getDroneHistoryList({ commit, state }, pagination = 1) {
            const type = 'droneHistory';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_DRONE_HISTORY_LIST, {
                    params: {
                        droneId: state.droneId,
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // droneHistory列表
        getMockDroneHistoryList({ commit, state }, pagination = 1) {
            const type = 'droneHistory';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_MOCK_DRONE_HISTORY_LIST, {
                    params: {
                        droneId: state.droneId,
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // droneHistory：变更列表分页Size
        changeDroneHistoryListPageSize({ commit, dispatch, state }, size) {
            const type = 'droneHistory';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // droneHistory：更新列表过滤条件
        changeDroneHistoryListFilters({ commit, dispatch, state }, filters) {
            const type = 'droneHistory';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // droneHistory：清空列表过滤条件
        clearDroneHistoryListFilters({ commit, state }) {
            const type = 'droneHistory';

            const f = helpers.update(state[`${type}Filters`], {
                startTime: '',
                endTime: '',
            });
            commit(types.UPDATE_FILTERS, { type, filters: f });
        },

        // droneHistory：获取架次详情 startTime
        getDroneHistoryDetail({ commit }, { droneId, sortieId, startTime }) {
            const type = 'droneHistory';
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_DRONE_SORTIE_DETAIL, {
                    params: {
                        droneId,
                        sortieId,
                        startTime,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // batchDroneHistoryDetails: 批量获取详情
        batchGetDroneHistoryDetail({ commit }, params = []) {
            const type = 'batchDroneHistoryDetails';
            commit(types.UPDATE_GROUP, { group: type });

            let count = params.length;
            if (!count) return Promise.resolve();

            return new Promise((resolve, reject) => {
                params.forEach(({ droneId, sortieId, startTime }) => {
                    Vue.http.get(urls.GET_DRONE_SORTIE_DETAIL, {
                        params: { droneId, sortieId, startTime },
                    }).then((response) => {
                        // console.log('aa', { group: type, key: sortieId, value: response.data });
                        commit(types.UPDATE_GROUP, { group: type, key: sortieId, value: response.data });
                        count -= 1;
                        if (count <= 0) resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                });
            });
        },

        // batchDroneHistoryDetails: 清除批量详情
        clearBatchDroneHistoryDetail({ commit }) {
            const type = 'batchDroneHistoryDetails';
            commit(types.UPDATE_GROUP, { group: type });
        },

        // droneHistoryStat：获取架次统计
        getDroneHistoryStatDetail({ commit }, { droneId, startTime, endTime }) {
            const type = 'droneHistoryStat';

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.GET_DRONE_SORTIES_STAT, droneId);
                Vue.http.get(url, {
                    params: {
                        startTime,
                        endTime,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // deviceWorkingPicture
        getDeviceWorkingPictures({ commit }, { droneId, startTime, endTime }) {
            const type = 'deviceWorkingPicture';

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.GET_DEVICE_WORKING_PICTURES, droneId);
                Vue.http.get(url, {
                    params: { droneId, startTime, endTime },
                }).then((response) => {
                    const list = response.data || [];
                    // const list = [{
                    //     "pictureId": 1,
                    //     "pictureUrl": "http://jiagu-pic.obs.cn-east-2.myhuaweicloud.com/sortie-pic/18040150/0bed4f60ba833d6bb714b174731afe9c.jpg",
                    //     "lng": 118.7339272,
                    //     "lat": 31.9841881,
                    //     "timestamp": 1623812508005,
                    //     "fileName": "0bed4f60ba833d6bb714b174731afe9c.jpg"
                    // }, {
                    //     "pictureId": 2,
                    //     "pictureUrl": "http://jiagu-pic.obs.cn-east-2.myhuaweicloud.com/sortie-pic/18040150/0bed4f60ba833d6bb714b174731afe9c.jpg",
                    //     "lng": 118.7337849,
                    //     "lat": 31.9842307,
                    //     "timestamp": 1623812508005,
                    //     "fileName": "0bed4f60ba833d6bb714b174731afe9c.jpg"
                    // }, {
                    //     "pictureId": 3,
                    //     "pictureUrl": "http://jiagu-pic.obs.cn-east-2.myhuaweicloud.com/sortie-pic/18040150/0bed4f60ba833d6bb714b174731afe9c.jpg",
                    //     "lng": 118.7337849,
                    //     "lat": 31.9842307,
                    //     "timestamp": 1623812508005,
                    //     "fileName": "0bed4f60ba833d6bb714b174731afe9c.jpg"
                    // }];
                    commit(types.UPDATE_LIST, { type, list });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        getDroneBatteryInfo(_, { id }) {
            // const type = 'droneHistory';

            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_BATTERY_INFO, {
                    params: {
                        sortieId: id,
                    },
                }).then((response) => {
                    // commit(types.UPDATE_LIST, { type, list: response.data });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // end
    },
};
