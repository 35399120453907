import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import * as auth from '../utils/auth';
import { accountTypes, userTypes, subPlatformType, domainNameRoute } from '../config/routeAccessibility';

import basePages from './basePages';
import statScreen from './statScreen';
import monitorManage from './monitorManage';
import businessManage from './businessManage';
import deviceManage from './deviceManage';
import accountManage from './accountManage';
import ownManage from './ownManage';
import bulletinManage from './bulletinManage';
import noFlyZoneManage from './noFlyZoneManage';
import dataStatManage from './dataStatManage';
import teamManage from './teamManage';
import leasingManage from './leasingManage';

Vue.use(Router);

const routes = [
    {
        path: '*',
        redirect: () => {
            const { [window.location.host]: routeNamesForDomainName = domainNameRoute.default } = domainNameRoute;
            const { userType = 0 } = store.state.user.userInfo || {};

            // console.log('userType', store.state.user.userInfo, userType);

            if (userType === 6) {
                return '/leasing_business/lease-list';
            }

            const pathNav = (store.getters['user/isPlatform'] && process.env.VUE_APP_BIG_SCREEN === 'yes' && routeNamesForDomainName.indexOf('StatScreen') >= 0);
            return (pathNav ? '/stat-screen' : '/monitor_manage/monitor');
        },
    },
    {
        path: '/redirect_to_account',
        redirect: '/institution_manage/account-detail',
    },
    ...basePages,
    ...statScreen,
    ...monitorManage,
    ...businessManage,
    ...deviceManage,
    ...accountManage,
    ...ownManage,
    ...bulletinManage,
    ...noFlyZoneManage,
    ...dataStatManage,
    ...teamManage,
    ...leasingManage,
];

const router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
});

// 路由钩子
router.beforeEach(async (to, from, next) => {
    store.dispatch('loading/uploadLoading', { isLoading: true });
    const isInsidePage = ['Login', 'Register', 'RetrievePassword'].indexOf(to.name) < 0;

    if (isInsidePage && !store.state.user.hasLoadedInitialData) {
        await Promise.all([
            store.dispatch('user/getRentalModelByAccount'),
            store.dispatch('user/getManufacturerByAccount'),
        ]);
        store.commit('user/SET_INITIAL_DATA_LOADED', true);
    }

    const refreshToken = auth.takeUrlToken(to);
    if (isInsidePage && refreshToken) {
        try {
            await store.dispatch('user/requestRefreshLogin', { refreshToken });
        } catch (e) {
            return next({
                name: 'Login',
                replace: true,
            });
        }
    }

    // 未登录则跳转到登录页面
    if (isInsidePage && !auth.checkToken()) {
        return next({
            name: 'Login',
            replace: true,
        });
    }

    // 2023/9/13 子平台账号 -- 翼飞特
    const { tag = undefined, accountType = 0, userType = 0 } = store.state.user.userInfo || {};
    // 子平台
    if (tag && (accountType === 1)) {
        const { [tag]: routeNamesForSubPlatform = [] } = subPlatformType;
        const { [window.location.host]: routeNamesForDomainName = domainNameRoute.default } = domainNameRoute;
        if (isInsidePage && (routeNamesForSubPlatform.indexOf(to.name) < 0 || routeNamesForDomainName.indexOf(to.name) < 0)) {
            return next({
                path: '/monitor_manage/monitor',
                replace: true,
            });
        }
        return next();
    }

    // 账户类型和用户类型，决定路由的可访问性
    // const { accountType = 0, userType = 0 } = store.state.user.userInfo || {};
    const { [accountType]: routeNamesForAccount = [] } = accountTypes;
    const { [userType]: routeNamesForUser = [] } = userTypes;
    const { [window.location.host]: routeNamesForDomainName = domainNameRoute.default } = domainNameRoute;
    if (isInsidePage && (routeNamesForAccount.indexOf(to.name) < 0 || routeNamesForUser.indexOf(to.name) < 0 || routeNamesForDomainName.indexOf(to.name) < 0)) {
        return next({
            path: '/',
            replace: true,
        });
    }
    return next();
});
router.afterEach(() => {
    store.dispatch('loading/uploadLoading', { isLoading: false });
});

export default router;
