<template>
    <div class="columns is-gapless is-mobile ui-root">
        <div class="column is-hidden-touch ui-cover"></div>
        <div class="column is-5-widescreen is-4-fullhd ui-login" v-loading="isLoading">
            <div class="columns is-mobile is-centered ui-main-wrapper">
                <div class="column is-narrow ui-main">
                    <h1>{{ !isCn ? $t('title') : title }}</h1>
                    <el-tabs v-model="loginMethod" stretch>
                        <el-tab-pane :label="$t('password_login')" name="pwd">
                            <el-form ref="pwdLoginForm" :model="pwdLoginForm" :rules="pwdLoginRules" @keyup.enter.native="submitPwdForm('pwdLoginForm')">
                                <el-form-item prop="phoneNum">
                                    <el-input v-model="pwdLoginForm.phoneNum" name="username" :placeholder="$t('username_tips')">
                                        <el-select v-if="isPhoneAccount" v-model="mobilePhoneCountryCode" slot="prepend" :placeholder="$t('country_code')" style="min-width: 90px;">
                                            <el-option
                                                v-for="item in countryOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                                <div style="display: flex;align-items: center">
                                                    <img
                                                        style="width: 20px; height: 20px; object-fit: fill; margin-right: 8px"
                                                        :src="item.flag" />
                                                    <span style="color: #8492a6; font-size: 13px;">{{ item.value }}</span>
                                                </div>
                                            </el-option>
                                        </el-select>
                                        <el-tooltip slot="append" :content="isPhoneAccount ? $t('login_email') : $t('login_phone')" placement="top">
                                            <el-button icon="el-icon-refresh" @click="onSwitchAccountInput"></el-button>
                                        </el-tooltip>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="password">
                                    <el-input
                                        type="password"
                                        v-model="pwdLoginForm.password"
                                        minlength="8"
                                        maxlength="16"
                                        autocomplete="off"
                                        name="password"
                                        :placeholder="$t('password_tips')">
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="verifyCode">
                                    <el-input v-model="pwdLoginForm.verifyCode" autocomplete="off" :placeholder="$t('code_tips')">
                                        <el-button slot="append" class="ui-code" @click="handleRefreshCode">
                                            <img :src="codeUrl" alt="验证码">
                                        </el-button>
                                    </el-input>
                                </el-form-item>
                                <div class="columns is-mobile ui-buttons">
                                    <div class="column">
                                        <el-button type="primary" @click="submitPwdForm('pwdLoginForm')">{{ $t('form.login') }}</el-button>
                                    </div>
                                    <div class="column">
                                        <el-button @click="resetForm('pwdLoginForm')">{{ $t('form.reset') }}</el-button>
                                    </div>
                                </div>
                                <div class="columns is-gapless is-mobile has-text-left">
                                    <div class="column">
                                        <el-checkbox v-model="pwdLoginForm.rememberPassword">{{ $t('remember_password') }}</el-checkbox>
                                    </div>
                                    <div class="column is-narrow ui-forget-password" v-if="!isEn">
                                        <router-link to="/retrieve-password">{{ $t('forget_password') }}？</router-link>
                                    </div>
                                </div>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane :label="$t('sms_login')" name="sms" v-if="!isEn">
                            <el-form ref="smsLoginForm" :model="smsLoginForm" :rules="smsLoginRules" @keyup.enter.native="submitPwdForm('smsLoginForm')">
                                <el-form-item prop="phoneNum">
                                    <el-input v-model="smsLoginForm.phoneNum" name="username" :placeholder="$t('username_tips')">
                                        <el-select
                                            v-if="isPhoneAccount2"
                                            v-model="mobilePhoneCountryCode"
                                            slot="prepend"
                                            :placeholder="$t('country_code')"
                                            style="min-width: 90px;">
                                            <el-option
                                                v-for="item in countryOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                                <div style="display: flex;align-items: center">
                                                    <img
                                                        style="width: 20px; height: 20px; object-fit: fill; margin-right: 8px"
                                                        :src="item.flag" />
                                                    <span style="color: #8492a6; font-size: 13px;">{{ item.value }}</span>
                                                </div>
                                            </el-option>
                                        </el-select>

                                        <el-tooltip slot="append" :content="isPhoneAccount2 ? $t('login_email') : $t('login_phone')" placement="top">
                                            <el-button icon="el-icon-refresh" @click="onSwitchAccountInput2"></el-button>
                                        </el-tooltip>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="verifyCode">
                                    <el-input v-model="smsLoginForm.verifyCode" autocomplete="off" :placeholder="$t('enter_verification')">
                                        <CountdownButton slot="append" :label="$t('get_verification')" @click="handleRequestVerifyCode('smsLoginForm', $event)"></CountdownButton>
                                    </el-input>
                                </el-form-item>
                                <div class="columns is-mobile ui-buttons">
                                    <div class="column">
                                        <el-button type="primary" @click="submitSmsForm('smsLoginForm')">{{ $t('form.login') }}</el-button>
                                    </div>
                                    <div class="column">
                                        <el-button @click="resetForm('smsLoginForm')">{{ $t('form.reset') }}</el-button>
                                    </div>
                                </div>
                                <div class="columns is-gapless is-mobile has-text-left">
                                    <div class="column">
                                        <el-checkbox v-model="smsLoginForm.rememberPassword">{{ $t('remember_password') }}</el-checkbox>
                                    </div>
                                </div>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="ui-sep">
                        <span>{{ $t('no_account') }}<router-link to="/register" replace>{{ $t('sign_up_for_free') }}<i class="el-icon-d-arrow-right"></i></router-link></span>
                    </div>
                </div>
            </div>
            <div class="ui-dock">
                <span>Language </span>
                <Localization></Localization>
            </div>
        </div>
    </div>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import scrollbarMixin from '../../../mixins/scrollbarMixin';
    import { REQUEST_IMG_VERIFY_CODE } from '../../../config/urls';
    import CountdownButton from '../../../components/CountdownButton.vue';
    import Localization from '../../../components/Localization.vue';
    import { validateAccountNumber } from '../../../utils/validators';
    import countryCode from '../../../config/countryCode';

    export default {
        name: 'Login',

        components: { CountdownButton, Localization },

        mixins: [scrollbarMixin],

        // 路由守卫
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.handleRefreshCode();
            });
        },

        data() {
            return {
                mobilePhoneCountryCode: '+86',
                isPhoneAccount: true,
                isPhoneAccount2: true,
                loginMethod: 'pwd',
                codeUrl: `${REQUEST_IMG_VERIFY_CODE}?${Math.random()}`,
                pwdLoginForm: {
                    phoneNum: '',
                    password: '',
                    verifyCode: '',
                    rememberPassword: false,
                },
                smsLoginForm: {
                    phoneNum: '',
                    verifyCode: '',
                    rememberPassword: false,
                },
            };
        },

        computed: {
            ...mapGetters('user', ['getUserInfo', 'isIndia', 'isTW']),
            ...mapState('theme', ['title', 'logoFilename']),
            ...mapState('lang', ['lang']),
            ...mapGetters('lang', ['isEn', 'isCn']),

            countryOptions() {
                return this.isIndia ? countryCode(['india']) : countryCode();
            },

            pwdLoginRules() {
                return {
                    phoneNum: [
                        { required: true, message: this.$t('username_rule'), trigger: 'blur' },
                        { validator: validateAccountNumber, messages: [this.$t('phone_tips'), this.$t('email_tips')], trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: this.$t('password_tips'), trigger: 'blur' },
                        { pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/, message: this.$t('password_rule'), trigger: 'blur' },
                    ],
                    verifyCode: [
                        { required: true, message: this.$t('code_tips'), trigger: 'blur' },
                        // this.isEn ? {} : { pattern: /^\d{4}$/g, message: this.$t('valid_code_tips'), trigger: 'blur' },
                        this.isEn ? {} : { required: true, message: this.$t('valid_code_tips'), trigger: 'blur' },
                    ],
                };
            },

            smsLoginRules() {
                return {
                    phoneNum: [
                        // { required: true, message: this.$t('phone_tips'), trigger: 'blur' },
                        { required: true, message: this.$t('username_rule'), trigger: 'blur' },
                        // { pattern: /^1[3-9][0-9]\d{8}$/g, message: '请输入正确的手机号码', trigger: 'blur' },
                        // { pattern: /^\d+$/g, message: this.$t('phone_tips'), trigger: 'blur' },
                        { validator: validateAccountNumber, messages: [this.$t('phone_tips'), this.$t('email_tips')], trigger: 'blur' },
                    ],
                    verifyCode: [
                        { required: true, message: this.$t('code_tips'), trigger: 'blur' },
                        // { pattern: /^\d{4}$/g, message: this.$t('valid_code_tips'), trigger: 'blur' },
                        { required: true, message: this.$t('valid_code_tips'), trigger: 'blur' },
                    ],
                };
            },
        },

        watch: {
            isEn(val) {
                if (val) {
                    this.loginMethod = 'pwd';
                }
            },
        },

        mounted() {
            if (this.isIndia) this.mobilePhoneCountryCode = '+91';
            if (this.isTW) this.mobilePhoneCountryCode = '+886';
        },

        methods: {
            ...mapActions('user', ['requestSmsVerifyCode', 'requestPasswordLogin', 'requestSmsLogin']),

            // 刷新图形验证码
            handleRefreshCode() {
                this.codeUrl = `${REQUEST_IMG_VERIFY_CODE}?${Math.random()}`;
                this.pwdLoginForm.verifyCode = '';
            },

            // 请求短信验证码
            handleRequestVerifyCode(formName, { countdownStart, countdownReset }) {
                this.$refs[formName].validateField('phoneNum', (validateMessage, invalidFields) => {
                    if (invalidFields) {
                        return;
                    }
                    // 开始倒计时
                    countdownStart();
                    this.startLoading();
                    this.requestSmsVerifyCode({
                        // phoneNum: this.smsLoginForm.phoneNum,
                        phoneNum: `${this.mobilePhoneCountryCode}-${this.smsLoginForm.phoneNum}`,
                        codeType: '1',
                    }).then(() => {
                        this.stopLoading();
                        this.$message.success('验证码已发送');
                    }).catch(({ message }) => {
                        // 恢复倒计时按钮原始状态
                        countdownReset();
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 密码登录
            submitPwdForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.startLoading();
                    // 如是手机号 加上国家码
                    const { phoneNum: account } = this.pwdLoginForm;
                    const phoneNum = this.isPhoneAccount ? `${this.mobilePhoneCountryCode}-${account}` : account;

                    this.requestPasswordLogin({ ...this.pwdLoginForm, phoneNum }).then(() => {
                        this.stopLoading();
                        this.$message.success(this.$t('dialog.login_success'));
                        this.resetForm(formName);
                        this.handleRefreshCode();
                        this.handleLoginSuccess();
                    }).catch(({ message }) => {
                        this.stopLoading();
                        this.handleRefreshCode();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 验证码登录
            submitSmsForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.startLoading();
                    // 加上国家码
                    // const { phoneNum: account } = this.smsLoginForm;
                    // const phoneNum = `${this.mobilePhoneCountryCode}-${account}`;

                    // 如是手机号 加上国家码
                    const { phoneNum: account } = this.smsLoginForm;
                    const phoneNum = this.isPhoneAccount2 ? `${this.mobilePhoneCountryCode}-${account}` : account;

                    this.requestSmsLogin({ ...this.smsLoginForm, phoneNum }).then(() => {
                        this.stopLoading();
                        this.$message.success('登录成功');
                        this.resetForm(formName);
                        this.handleLoginSuccess();
                    }).catch(({ message }) => {
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 重置表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

            // 登出成功后
            handleLoginSuccess() {
                const accountType = this.getUserInfo('accountType');
                const checkStatus = this.getUserInfo('checkStatus');
                // 协会、政府、其他账户已通过审核的，都跳转到根url
                if (accountType === 1 || accountType === 2 || checkStatus === 2) {
                    if (this.isIndia) {
                        window.location.href = window.location.origin;
                    } else {
                        this.$router.replace('/');
                    }
                } else if (this.isIndia) {
                    window.location.href = `${window.location.origin}/redirect_to_account`; // window.location.origin + '/redirect_to_account';
                } else {
                    this.$router.replace('/redirect_to_account');
                }
            },

            // 切换账号输入框
            onSwitchAccountInput() {
                this.isPhoneAccount = !this.isPhoneAccount;
                // 副作用
                this.pwdLoginForm.phoneNum = '';
            },

            // 切换账号输入框
            onSwitchAccountInput2() {
                this.isPhoneAccount2 = !this.isPhoneAccount2;
                // 副作用
                this.smsLoginForm.phoneNum = '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-root {
        height: inherit;
        .ui-cover {
            background: url("./images/login-cover.webp") no-repeat scroll left center transparent;
            background-size: cover;
        }
        .ui-login {
            position: relative;
            box-shadow: -4px 0 15px rgba(0, 0, 0, 0.12);
        }
        .ui-main-wrapper {
            align-items: center;
            height: 100%;
        }
        .ui-main {
            text-align: center;
            min-width: 450px;
            min-height: 500px;
            h1 {
                font-size: 20px;
                color: $brandColor;
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 40px;
            }
            .el-input-group--append /deep/ .el-input-group__append {
                background-color: white;
            }
            .ui-code {
                padding: 4px 0;
                & /deep/ span {
                    display: inline-block;
                    width: 130px;
                    height: 34px;
                }
                img {
                    height: 100%;
                }
            }
            .ui-buttons {
                margin-top: 25px;
                margin-bottom: 0;
                button {
                    width: 100%;
                }
            }
            .ui-forget-password a {
                font-size: 14px;
            }
            .ui-sep {
                position: relative;
                margin-top: 24px;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $grey-lighter;
                    position: absolute;
                    top: 50%;
                }
                span {
                    position: relative;
                    display: inline-block;
                    width: 50%;
                    background-color: #fff;
                    font-size: 14px;
                }
            }
        }
        .ui-dock {
            position: absolute;
            left: 0;
            bottom: 20px;
            right: 0;
            text-align: center;
        }
    }
</style>
